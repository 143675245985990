import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const GrundbuchKosten = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Infos zum Grundbuchauszug – Was du wissen solltest" showCalc={false} />
            <Article>
                <p>
                    Jede Immobilie oder Liegenschaft wird im Grundbuch eingetragen. Wenn du also Eigentümerin oder
                    Eigentümer wirst, ist es wichtig eine Eintragung ins Grundbuch zu machen. Zu diesem Thema haben wir
                    bereits{" "}
                    <Link to="/artikel/grundbuch-kosten/" target="_blank" rel="noreferrer noopener">
                        einen Artikel
                    </Link>{" "}
                    für dich verfasst. Wenn du dich über die rechtlichen Eigentümer*innen einer Immobilie interessierst,
                    kannst du jederzeit Einsicht ins Grundbuch verlangen. Diese Einsicht erhältst du durch einen
                    Grundbuchauszug. Was du darüber wissen solltest, erfährst du hier.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was steht im Grundbuch eigentlich?</h2>
                <p>
                    Das Grundbuch ist ein öffentliches Register, in dem die Grundstücke, Rechte, bestehende
                    Eigentumsverhältnisse und die damit verbundenen Belastungen verzeichnet werden. Das Grundbuch
                    besteht aus dem Hauptbuch, dem Verzeichnis von gelöschten Eintragungen, dem sogenannten
                    Löschungsverzeichnis, anderen Hilfsverzeichnissen, einer Urkundensammlung und der Grundbuchsmappe.
                </p>
                <p>
                    Im Grundbuch werden also Grundstücke, Eigentümer*innen und alle in diesem Zusammenhang bestehenden
                    Rechte und Belastungen aufgezeichnet. Es ist für alle öffentlich einsehbar. Durch einen
                    Grundbuchauszug bekommst du alle wichtigen Informationen zu jeder Immobilie, jedem Grundstück, und
                    auch Firmen. Das Grundbuch besteht aus einem A-Blatt, in dem Angaben zur Liegenschaft gemacht
                    werden, dem B-Blatt, in welchem Angaben zu Eigentümer*innen zu finden sind und einem C-Blatt, wo die
                    Angaben zu Belastungen der Liegenschaft stehen, falls welche vorhanden sind.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wie bekomme ich den Grundbuchauszug?</h2>
                <p>
                    Mittlerweile erhält man innerhalb sehr kurzer Zeit online durch ein paar Klicks einen
                    Grundbuchauszug. Dieser wird auf vielen unterschiedlichen Webseiten angeboten. Dort reicht es
                    meistens aus, die Adresse einzugeben, um die Grundstücksnummer (GSTNR) und die Einlagezahl (EZ) der
                    Liegenschaft herauszufinden. Aber du erhältst den Grundbuchauszug auch bei jedem Bezirksgericht oder
                    Notar.
                </p>
                <hr />

                <h2>Die Kosten</h2>
                <p>
                    Der Grundbuchauszug kostet meist 14,40€ und kann bei jedem Bezirksgericht, Notar und auch online
                    angefragt werden. An diesem Preis kannst du dich orientieren. Falls auf einer Website mehr Geld
                    dafür verlangt wird, solltest du dich nach einem anderen Anbieter umsehen. Für die Eintragung ins
                    Grundbuch musst du hingegen recht hohe Gebühren zahlen, genauso wie auch für jede Änderung und
                    Löschung. Wie es mit den{" "}
                    <Link to="/artikel/notarkosten/" target="_blank" rel="noreferrer noopener">
                        Kosten
                    </Link>{" "}
                    rund ums Grundbuch genauer aussieht, erfährst du in diesem Artikel. Und wenn du unseren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    noch nicht kennst, kannst du ihn auf dieser Seite ausprobieren.
                </p>
                <hr />

                <h2>Warum du den Grundbuchauszug vor jedem Immobilienkauf einholen solltest</h2>
                <p>
                    Wenn du Grund und Boden oder eine Immobilie kaufst, darfst du nicht darauf verzichten, einen
                    Grundbuchauszug zu verlangen. Du musst überprüfen, wer „bücherlicher“ Eigentümer oder Eigentümerin
                    des Objekts ist und welche Belastungen eingetragen sind. Wichtig zu wissen ist auch, dass alle
                    Umstände, die aus dem Grundbuch zu erfahren sind, aber dennoch von dir übersehen wurden, später
                    nicht mehr als Mangel geltend gemacht werden können. Vor jedem Immobilienkauf solltest du dich also
                    so genau wie nur möglich über die Immobilie informieren.
                </p>
                <p>
                    Wenn du unseren Kreditrechner noch nicht kennst, kannst du ihn{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        hier ausprobieren
                    </Link>
                    .
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"grundbuchauszug"}></BreadcrumbList>
            <ArticleStructuredData
                page={"grundbuchauszug"}
                heading={"Infos zum Grundbuchauszug – Was du wissen solltest"}
            />
        </Layout>
    );
};

export default GrundbuchKosten;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.grundbuchauszug", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
